import { RootStoreState } from 'store/reducers';
import {
  selectMostRecentTimezone,
  selectMostRecentZip,
} from 'store/consumer/selectors';
import { isUserLoggedIn } from 'store/auth/selectors';

import { createAPIAction } from 'store/utils/createAPIAction';
import { createAction } from 'store/utils/createAction';
import { serverDate } from 'utilities/formatting';
import { isNull } from 'lodash';
import queryString from 'query-string';
import { getCookie, setAuthCookies } from 'utilities/cookie';
import { RS_CONVERSION_INFO } from 'constants/cookies';
import {
  updateCarDetails,
  addNewCar,
  getCars,
  getAppointments,
  getAddresses,
} from 'store/consumer/actions';
import { registerUser, setTokensFromCookie } from 'store/auth/actions';
import {
  trackAnalyticsEvent,
  identifyUserEvent,
  setSnackbarMessage,
} from 'store/global/actions';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TRIM_MISSED_ID } from 'constants/car-selector';
import { PreliminaryCar } from 'entities/PreliminaryCar';
import { Car } from 'entities/Car';
import { KnowRepair, PreliminaryKnowRepair } from 'entities/KnowRepair';
import { Shop } from 'entities/Shop';
import { Diagnose, PreliminaryDiagnose } from 'entities/Diagnose';
import { CustomRequest } from 'entities/CustomRequest';
import { Address } from 'entities/Address';
import { Coordinates } from 'entities/Coordinates';
import { AppointmentData } from 'entities/AppointmentData';
import { VwoExperimentViewed } from 'entities/vwoExperimentViewed';
import { AcceptRecommendedRepairsResponse } from 'entities/AcceptRecommendedRepairsResponse';
import { Note } from 'entities/Note';
import { Moment } from 'moment';

import {
  getAppointment,
  removeCustomRequestApiCall,
  removeDiagnosisApiCall,
  removeKnowRepairApiCall,
  submitAllRepairs,
  submitCustomRequest,
  submitDiagnosis,
  submitKnownRepair,
  makeRepairRequest,
  MakeRepairRequestParams,
} from 'store/repairRequest/actions';
import { Visit } from 'entities/Visit';
import { BookingData } from 'entities/Booking';
import { DeliveryChannel } from 'entities/RepairRequest';
import { Credit } from 'entities/Credit';
import { Coordinate } from 'store/shops/types';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { AddRepairRequestResponse } from './reducer';
import {
  selectRepairRequestDataForSubmit,
  findActiveAppointmentForCar,
  selectZip,
  selectTimezone,
} from './selectors';
import {
  START_HOMEPAGE_BOOKING_PATH,
  START_PATH_AFTER_QUOTE_UPDATED,
} from './constants';
import {
  clearLiveDeliverySchedule,
  getShopsByZip,
  setShopDetails,
} from '../shops/actions';

const { CAR_SELECTOR_FINAL_FIELD, FIREBASE_MOBILE_VALIDATION_ENABLED } =
  window.RepairSmith;
const firebaseMobileValidationEnabled =
  FIREBASE_MOBILE_VALIDATION_ENABLED === 'true';

const serviceName = 'preliminaryRepair';

export const handleAnalytics = (data: any) => {
  const {
    selectedCar,
    selectedShop,
    zip,
    schedule,
    symptomsStr,
    repairsStr,
    requestedServiceTypes,
    repairRequestId,
  } = data;

  const eventName =
    schedule && selectedShop ? 'Request Appointment' : 'Request Quote';

  trackAnalyticsEvent(eventName, {
    Date_of_Appointment: schedule ? serverDate(schedule.date) : null,
    Time_of_Appointment: schedule?.time ?? null,
    Selected_Repair_Shop: selectedShop?.shopName ?? null,
    Selected_Shop_Tier: selectedShop?.tier ?? null,
    Repair_Path: requestedServiceTypes,
    Engine: selectedCar.trim.value,
    Car_Model: selectedCar.model.value,
    Zip_Code: zip,
    path: selectedCar.path,
    Car_Year: selectedCar.year.value,
    Car_Make: selectedCar.make.value,
    VIN: selectedCar?.vin?.value ?? null,
    License_Plate: selectedCar?.plate?.value ?? null,
    License_Plate_State: selectedCar?.plate?.state ?? null,
    Vehicle_Issue_Reported: symptomsStr,
    possible_repairs: repairsStr,
    Promo_Code: data?.promoCode ?? null,
    Contact_Path: schedule && selectedShop ? 'Self_Serve' : 'Lead_Form',
    Repair_Request_Id: repairRequestId,
  });
};

export const handleIdentify = (data: any) => {
  const {
    selectedCar,
    zip,
    selectedShop,
    schedule,
    requestedServiceTypes,
    repairsStr,
    symptomsStr,
    user,
  } = data;
  const userData = user?.consumer ?? null;

  if (!isNull(userData)) {
    const IdentifyUserData = {
      RecordType: 'Customer',
      Type_Of_Inquiry: 'Appointment',
      Booking_Channel: 'Product',
      Status: 'Repair Appointment Scheduled',
      Zip_Code: zip,
      Tracking_ID: data?.trackingId,
      ...userData,
    };

    const requestInfo = {
      Car_Make: selectedCar?.make?.value ?? '',
      Car_Model: selectedCar?.model?.value ?? '',
      Car_Year: parseInt(selectedCar?.year?.value ?? 0, 10),
      Engine: selectedCar?.engine?.value ?? '',
      License_Plate: selectedCar?.license?.value ?? '',
      License_Plate_State: selectedCar?.plate?.state ?? null,
      VIN: selectedCar?.vin?.value ?? '',
      Date_of_Appointment: schedule ? serverDate(schedule.date) : null,
      Time_of_Appointment: schedule?.time ?? null,
      Selected_Repair_Shop: selectedShop?.shopName ?? null,
      Repair_Shop_address: `${
        selectedShop?.address ?? 'No address available'
      }, ${selectedShop?.city ?? 'No city available'}, ${
        selectedShop?.state ?? 'No state available'
      }, ${selectedShop?.zip ?? 'No zip available'}`,
      Repair_Path: requestedServiceTypes,
      path: selectedCar.path,
      Vehicle_Issue_Reported: symptomsStr,
      possible_repairs: repairsStr,
    };

    handleAnalytics(data);
    return identifyUserEvent(IdentifyUserData, requestInfo);
  }
};

export const setStartPath = createAction<string | null>(
  `${serviceName}/SET_START_PATH`
);

export const setDeclineRepairs = createAction<string[]>(
  `${serviceName}/SET_DECLINE_REPAIRS`
);
export const clearDeclineRepairs = createAction(
  `${serviceName}/CLEAR_DECLINE_REPAIRS`
);

export const setIsDataFromQuery = createAction(
  `${serviceName}/SET_DATA_FROM_QUERY`
);

export const clearRequestInfo = createAction(
  `${serviceName}/CLEAR_REQUEST_INFO`
);

/*
 * CAR ACTIONS
 */
export const setCarSelectionAttr = createAction<Partial<PreliminaryCar>>(
  `${serviceName}/SET_CAR_ATTR`
);

export const setCarByExistingCar = (car: Car) => (dispatch: Dispatch) => {
  dispatch(
    setCarSelectionAttr({
      year: {
        value: car.year ?? car.carYear,
      },
      make: {
        id: car.makeId,
        value: car.make,
      },
      model: {
        id: car.modelId,
        value: car.model,
      },
      trim: {
        id: car.subModelId,
        value: car.trim,
      },
      engine: {
        id: car.engineId,
        value: car.engineDescription,
      },
      vin: {
        value: car.vin ?? null,
      },
      plate: {
        value: car.plateNumber,
        state: car.plateNumberState,
      },
      mileage: {
        value: car.mileage,
      },
      carId: {
        value: car.id,
      },
      nickname: car.nickname ?? undefined,
    })
  );
};

export const clearCarSelectionAttr = createAction(
  `${serviceName}/CLEAR_CAR_ATTR`
);

export const resetCarSelection = createAction(`${serviceName}/RESET_CAR_ATTRS`);

export const setCarImage = createAction(`${serviceName}/SET_CAR_IMAGE`);

/*
 * SUBMIT REPAIR REQUEST ACTIONS
 */
export const setRepairRequestError = createAction<{ message: string }>(
  `${serviceName}/SET_REPAIR_REQUEST_ERROR`
);

export const addRepairRequestWithNewCar =
  (data: MakeRepairRequestParams & { car?: Car }) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const carData = {
      carDetails: [data.car],
    };
    const response = await dispatch(addNewCar(carData as any));

    const carId = response?.payload?.carDetails[0]?.id;
    const tRepairData = { ...data };
    tRepairData.carId = carId;

    dispatch(setCarSelectionAttr({ carId: { value: carId } }));
    delete tRepairData.car;

    const makeRepairRequestResponse = await dispatch(
      makeRepairRequest(tRepairData)
    );

    if (makeRepairRequestResponse.payload instanceof Error) {
      dispatch(clearLiveDeliverySchedule());
      dispatch(setRepairRequestError(makeRepairRequestResponse.payload));
    }

    return makeRepairRequestResponse;
  };

export const addRepairRequestWithExistingCar = createAPIAction<
  [MakeRepairRequestParams],
  any,
  AddRepairRequestResponse
>(`${serviceName}/ADD_REPAIR`, (data) => ({
  endpoint: '/consumer-service/repair-request',
  method: 'POST',
  data,
}));

export const addAddressToAppointment = createAPIAction<
  [
    {
      address: Address;
      appointmentId: string;
      availableDeliveryChannels: DeliveryChannel[];
      consumerPreferredTimes: string[];
      timezone: string;
    },
  ],
  any,
  AddRepairRequestResponse
>(`${serviceName}/ADD_ADDRESS_TO_APPOINTMENT`, (data) => {
  return {
    endpoint: `/repair-service/consumer/appointments/v2/${data.appointmentId}`,
    method: 'POST',
    data,
  };
});

export const saveBookingDataForLater = createAction<BookingData>(
  `${serviceName}/SAVE_BOOKING_DATA_FOR_LATER`
);

export const setDeliveryAddress = createAction<Address>(
  `${serviceName}/SET_DELIVERY_ADDRESS`
);

export const setDeliveryCoordinates = createAction<Coordinates>(
  `${serviceName}/SET_DELIVERY_COORDINATES`
);

export const setArrivalInstructions = createAction<string>(
  `${serviceName}/SET_ARRIVAL_INSTRUCTIONS`
);

export const setShop = createAction<Shop>(`${serviceName}/SET_SHOP`);

export const setZip = createAction<string>(`${serviceName}/SET_ZIP`);

export const setVisit = createAction<Visit>(`${serviceName}/SET_VISIT`);

export const setCoordinates = createAction<Coordinate>(
  `${serviceName}/SET_COORDINATES`
);

export const setTimezone = createAction<string>(`${serviceName}/SET_TIMEZONE`);

/*
SERVICES
 */

export const clearServices = createAction(`${serviceName}/CLEAR_SERVICES`);

const productAdded = createAPIAction(
  `${serviceName}/PRODUCT_ADDED`,
  (params) => ({
    endpoint: `/pricing-service/quote/product-added?${queryString.stringify(
      params
    )}`,
    method: 'GET',
  })
);

const prefetchPrice =
  (
    knownRepair: PreliminaryKnowRepair,
    car: PreliminaryCar | null,
    source?: string
  ) =>
  (dispatch: ThunkDispatch<any, any, any>, getState: Function) => {
    const state = getState();
    const carInfo: PreliminaryCar =
      car || (state.preliminaryRepair && state.preliminaryRepair.car);
    dispatch(
      productAdded({
        source,
        productId: knownRepair.nodeId,
        year: carInfo.year.value,
        make: carInfo.make.value,
        makeId: carInfo.make.id,
        model: carInfo.model.value,
        modelId: carInfo.model.id,
        engineId: carInfo.engine && carInfo.engine.id,
        trim: carInfo.trim && carInfo.trim.value,
        subModelId: carInfo.trim && carInfo.trim.id,
        vin: carInfo.vin?.value,
      })
    );
  };

export const addKnownRepair = createAction<PreliminaryKnowRepair>(
  `${serviceName}/ADD_KNOWN_REPAIR`
);

export const addKnownRepairPrefetchPrice =
  (
    knownRepair: PreliminaryKnowRepair,
    car: PreliminaryCar | null,
    source: string
  ) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    await dispatch(addKnownRepair(knownRepair));
    await dispatch(prefetchPrice(knownRepair, car, source));
  };

export const removeKnownRepair = createAction<{ nodeId: number }>(
  `${serviceName}/REMOVE_KNOWN_REPAIR`
);

export const clearKnownRepairs = createAction(
  `${serviceName}/CLEAR_KNOWN_REPAIRS`
);

export const addDiagnosis = createAction<Diagnose>(
  `${serviceName}/ADD_DIAGNOSIS`
);

export const clearDiagnoses = createAction(`${serviceName}/CLEAR_DIAGNOSES`);

export const addCustomServiceRequest = createAction<string>(
  `${serviceName}/ADD_CUSTOM_REQUEST`
);

export const removeCustomServiceRequest = createAction<{ message: string }>(
  `${serviceName}/REMOVE_CUSTOM_REQUEST`
);

export const showUpdatedRepairRequestSnackbar =
  () => (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(setStartPath(START_PATH_AFTER_QUOTE_UPDATED));
    dispatch(setSnackbarMessage('We’ve updated your request'));
  };

export const mergeRepairRequestAnalytics = (
  services: string,
  trackerState: string
) => {
  trackAnalyticsEvent('Requests Merged', {
    'Selected Services': services,
    'Current Request State': trackerState,
  });
};

export type SubmitRepairRequestProps = {
  zip: string | null;
  timezone: string | null;
  userLoggedIn: boolean;
  selectedShop?: Shop;
  selectedCar: PreliminaryCar | null;
  userRegistrationData: any;
  repairsStr: string;
  symptomsStr: string;
  address?: string;
  services: {
    knownRepairs: PreliminaryKnowRepair[];
    diagnoses: Diagnose[];
    customRequests: string[];
    totalCost?: number | null;
    totalLaborDuration?: number | null;
    shopId?: number;
  };
  requestedServiceTypes: string;
  quoteId?: string | null;
  fleetId?: string | null;
  vwoExperimentsViewed?: VwoExperimentViewed[];
  note?: Note;
  consumerId?: number;
  funnelId?: string;
  firstSlotAvailable?: string;
  firstSlotDuration?: number;
  trackingId?: string;
  requestCredit?: Credit;
};

export const saveNote = createAPIAction<
  [
    {
      consumerId: number;
      entityType: string;
      entityId: string;
      content?: string | null;
      repairRequestId: string;
      type?: string;
    },
  ],
  any,
  Note
>(
  `${serviceName}/SAVE_NOTE`,
  ({ consumerId, entityType, entityId, content, repairRequestId, type }) => {
    return {
      endpoint: `/repair-service/consumers/${consumerId}/notes`,
      method: 'POST',
      data: {
        entityType,
        entityId,
        content,
        origin: 'CONSUMER',
        repairRequestId,
        type: type ?? 'CONSUMER',
      },
    };
  }
);

export const submitRepairRequest =
  (data: SubmitRepairRequestProps) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const {
      userLoggedIn,
      selectedShop,
      selectedCar,
      userRegistrationData,
      services,
      zip,
      timezone,
      address,
      repairsStr,
      quoteId,
      fleetId,
      vwoExperimentsViewed,
      note,
      consumerId,
      funnelId,
      firstSlotAvailable,
      firstSlotDuration,
      requestCredit,
    } = data;

    const trimId = selectedCar?.trim?.id ?? null;
    const subModelId =
      (trimId as unknown as string) === TRIM_MISSED_ID ? null : trimId;

    const carData: Car = {
      make: (selectedCar?.make?.value ?? null) as unknown as string,
      makeId: (selectedCar?.make?.id ?? null) as unknown as number,
      model: (selectedCar?.model?.value ?? null) as unknown as string,
      modelId: (selectedCar?.model?.id ?? null) as unknown as number,
      year: +(selectedCar?.year?.value ?? 0),
      vin: (selectedCar?.vin?.value ?? null) as unknown as string,
      plateNumber: selectedCar?.plate?.value ?? null,
      plateNumberState: selectedCar?.plate?.state ?? null,
      residualValue: selectedCar?.residualValue ?? null,
      nickname: selectedCar?.nickname ?? undefined,
    };

    if (CAR_SELECTOR_FINAL_FIELD === 'trim') {
      carData.trim = (selectedCar?.trim?.value ?? null) as unknown as string;
      carData.subModelId = subModelId as unknown as number;
    } else if (CAR_SELECTOR_FINAL_FIELD === 'engine') {
      carData.engineId = selectedCar?.engine?.id ?? null;
      carData.engineDescription = (selectedCar?.engine?.value ??
        null) as unknown as string;
    }

    const repairRequestData: MakeRepairRequestParams = {
      shopId: selectedShop?.id ?? null,
      zip,
      timezone,
      services,
      carResidualValue: selectedCar?.residualValue ?? null,
      quoteId,
      fleetId,
      funnelId,
      firstSlotAvailable,
      firstSlotDuration,
    };

    if (userLoggedIn) {
      const carId = selectedCar?.carId?.value ?? null;
      if (!isNull(carId)) {
        repairRequestData.carId = carId;
        try {
          const carDetailsResponse = await dispatch(
            updateCarDetails({ ...carData, carId })
          );

          if (carDetailsResponse.payload instanceof Error) {
            dispatch(clearLiveDeliverySchedule());
            dispatch(setRepairRequestError(carDetailsResponse.payload));
          }

          const appointmentsResponse = await dispatch(getAppointments());

          if (appointmentsResponse.payload instanceof Error) {
            dispatch(clearLiveDeliverySchedule());
            dispatch(setRepairRequestError(appointmentsResponse.payload));
            return appointmentsResponse.payload;
          }

          const firstActiveAppointment = findActiveAppointmentForCar(
            appointmentsResponse.payload,
            carId
          );

          if (firstActiveAppointment && !requestCredit) {
            const refNum = firstActiveAppointment.repairRequest.referenceNum;
            await dispatch(
              submitAllRepairs({
                refNum,
                knownRepairs: services.knownRepairs,
                customRequests: services.customRequests,
                diagnoses: services.diagnoses,
              })
            );

            mergeRepairRequestAnalytics(
              repairsStr,
              firstActiveAppointment.trackerState
            );

            dispatch(showUpdatedRepairRequestSnackbar());

            await dispatch(
              getAppointment({
                refNum,
              })
            );
          }
          const requestResponse = await dispatch(
            addRepairRequestWithExistingCar(repairRequestData)
          );

          if (requestResponse.error) {
            dispatch(clearLiveDeliverySchedule());
            throw requestResponse.payload;
          }

          handleAnalytics({
            ...data,
            repairRequestId: requestResponse.payload.repairRequestId,
          });

          if (note && consumerId) {
            dispatch(
              saveNote({
                consumerId,
                entityType: note.entityType,
                entityId: requestResponse.payload.repairRequestId,
                content: note.content,
                repairRequestId: requestResponse.payload.repairRequestId,
              })
            );
          }

          await dispatch(
            getAppointment({
              refNum: requestResponse.payload.repairRequestId,
            })
          );

          return requestResponse;
        } catch (e) {
          console.log(e); // eslint-disable-line no-console
        }
      } else {
        const repairRequestDataWithNewCar = {
          ...repairRequestData,
          car: carData,
        };
        try {
          const requestResponse = await dispatch(
            addRepairRequestWithNewCar(repairRequestDataWithNewCar)
          );

          if (requestResponse.error) {
            throw requestResponse.payload;
          }

          const { repairRequestId } = requestResponse.payload ?? {};
          if (!repairRequestId) return;

          handleAnalytics({
            ...data,
            repairRequestId,
          });

          if (note && consumerId) {
            dispatch(
              saveNote({
                consumerId,
                entityType: note.entityType,
                entityId: repairRequestId,
                content: note.content,
                repairRequestId,
              })
            );
          }

          await dispatch(
            getAppointment({
              refNum: repairRequestId,
            })
          );

          return requestResponse;
        } catch (e) {
          console.log(e); // eslint-disable-line no-console
        }
      }
    } else {
      // READ CONVERSION COOKIE FOR CONVERSION TRACKING DETAILS
      const conversionInfoCookie = getCookie(RS_CONVERSION_INFO);
      const conversionInfo = conversionInfoCookie
        ? JSON.parse(conversionInfoCookie)
        : {};

      if (userRegistrationData) {
        userRegistrationData.termsAccepted = true;
      }

      const newUserSubmitData = {
        consumer: userRegistrationData,
        car: carData,
        zipCode: zip,
        address,
        anonymousConsumerId: conversionInfo?.trackingId ?? null,
        landingUrl: conversionInfo?.url ?? null,
        referrerUrl: conversionInfo?.referrer ?? null,
        vwoTestData: JSON.stringify(vwoExperimentsViewed ?? []),
      };

      try {
        const userSubmitResponse = await dispatch(
          registerUser(newUserSubmitData)
        );
        if (userSubmitResponse.payload instanceof Error) {
          dispatch(clearLiveDeliverySchedule());
          dispatch(setRepairRequestError(userSubmitResponse.payload));
          return userSubmitResponse.payload;
        }

        setAuthCookies({ payload: userSubmitResponse.payload.loginResponse });
        dispatch(setTokensFromCookie(userSubmitResponse.payload.loginResponse));

        // LOGIN THE USER VIA FIREBASE
        if (firebaseMobileValidationEnabled) {
          const auth = getAuth();
          await signInWithCustomToken(
            auth,
            userSubmitResponse.payload.customToken
          );
        }

        const carId: number = userSubmitResponse.payload.car.id;
        const addRepairData = {
          ...repairRequestData,
          carId,
        };

        dispatch(
          setCarSelectionAttr({
            carId: { value: carId },
          })
        );

        const appointmentsResponse = await dispatch(getAppointments());

        if (appointmentsResponse.payload instanceof Error) {
          dispatch(clearLiveDeliverySchedule());
          dispatch(setRepairRequestError(appointmentsResponse.payload));

          return appointmentsResponse.payload;
        }

        const firstActiveAppointment = findActiveAppointmentForCar(
          appointmentsResponse.payload,
          carId
        );

        if (firstActiveAppointment) {
          const refNum = firstActiveAppointment.repairRequest.referenceNum;
          await submitAllRepairs({
            refNum,
            knownRepairs: services.knownRepairs,
            customRequests: services.customRequests,
            diagnoses: services.diagnoses,
          });

          mergeRepairRequestAnalytics(
            repairsStr,
            firstActiveAppointment.trackerState
          );

          handleIdentify({
            ...data,
            user: userSubmitResponse.payload,
            repairRequestId: firstActiveAppointment.repairRequest.referenceNum,
          });

          dispatch(showUpdatedRepairRequestSnackbar());
        } else {
          const requestRepairResponse = await dispatch(
            addRepairRequestWithExistingCar(addRepairData)
          );

          if (requestRepairResponse.payload instanceof Error) {
            dispatch(clearLiveDeliverySchedule());
            dispatch(setRepairRequestError(requestRepairResponse.payload));
            return requestRepairResponse.payload;
          }

          handleIdentify({
            ...data,
            user: userSubmitResponse.payload,
            repairRequestId: requestRepairResponse.payload.repairRequestId,
          });

          if (note) {
            dispatch(
              saveNote({
                consumerId: userSubmitResponse.payload.consumer.id,
                entityType: note.entityType,
                entityId: requestRepairResponse.payload.repairRequestId,
                content: note.content,
                repairRequestId: requestRepairResponse.payload.repairRequestId,
              })
            );
          }

          await dispatch(
            getAppointment({
              refNum: requestRepairResponse.payload.repairRequestId,
            })
          );
        }

        return {
          type: `${serviceName}/SUBMIT_REPAIR_REQUEST`,
          payload: {
            user: userSubmitResponse.payload,
          },
        };
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
        return e;
      }
    }
  };

interface CarResponse {
  year: number;
  modelId: number;
  makeId: number;
}

export const signInWithSubmitRepairRequest =
  ({
    selectedCar,
    repairRequestDataForSubmit,
  }: {
    selectedCar: PreliminaryCar;
    repairRequestDataForSubmit: ReturnType<
      typeof selectRepairRequestDataForSubmit
    >;
  }) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    const carsResponse = await dispatch(getCars());

    const similarCar = carsResponse.payload.carDetails.find(
      (car: CarResponse) =>
        car.makeId === selectedCar.make.id &&
        car.modelId === selectedCar.model.id &&
        car.year === selectedCar.year.id
    );

    if (similarCar) {
      selectedCar.carId = { value: similarCar.id };
    }

    const submitResponse = await (dispatch as ThunkDispatch<any, any, any>)(
      submitRepairRequest(
        repairRequestDataForSubmit as SubmitRepairRequestProps
      )
    );
    return submitResponse;
  };

export const populateFlow =
  (
    zip: string,
    knownRepairs: KnowRepair[],
    diagnoses: Diagnose[],
    customRequests: CustomRequest[],
    car: Car,
    vin?: string
  ) =>
  async (dispatch: Dispatch) => {
    const {
      consumerCarId = null,
      carYear = 0,
      makeId,
      make,
      modelId,
      model,
      subModelId,
      carTrim,
      mileage,
      engineId,
      engineDescription,
    } = car;

    const carData: PreliminaryCar = {
      carId: { value: consumerCarId },
      year: { id: carYear, value: carYear },
      make: { id: makeId, value: make },
      model: { id: modelId, value: model },
      mileage: { value: mileage },
      vin: { value: vin ?? null },
    };

    if (CAR_SELECTOR_FINAL_FIELD === 'trim') {
      carData.trim = { id: subModelId, value: carTrim };
    } else {
      carData.engine = { id: engineId, value: engineDescription };
    }

    await dispatch(clearRequestInfo());

    return Promise.all([
      dispatch(setZip(zip)),

      dispatch(setCarSelectionAttr(carData)),

      ...knownRepairs.map((repair) =>
        (dispatch as ThunkDispatch<any, any, any>)(
          addKnownRepairPrefetchPrice(
            {
              nodeId: repair.nodeId,
              name: repair.name,
            },
            carData,
            'preliminary-repair:populate-flow'
          )
        )
      ),

      ...diagnoses.map((diagnosis) =>
        dispatch(
          addDiagnosis({
            ...diagnosis,
            traverseNodes: diagnosis.symptoms.map((symptom) => ({
              nodeId: symptom.symptomId,
              message: null,
            })),
          })
        )
      ),

      ...customRequests.map((request) =>
        dispatch(addCustomServiceRequest(request.message))
      ),
    ]);
  };

export const updateServicesQuote = createAction<{
  services: {
    knownRepairs: KnowRepair[];
    diagnoses: Diagnose[];
    customRequests: CustomRequest[];
  };
  totalCost: number | null;
}>(`${serviceName}/UPDATE_SERVICES_QUOTE`);

export const updateServices = createAction<{
  knownRepairs: KnowRepair[];
  diagnoses: Diagnose[];
  customRequests: CustomRequest[];
}>(`${serviceName}/UPDATE_SERVICES`);

// editing repair request
export const removeKnownRepairQuote =
  ({
    repairId,
    id,
    nodeId,
  }: {
    repairId: string;
    id: number;
    nodeId: number;
  }) =>
  async (dispatch: Function) => {
    const res = await dispatch(
      removeKnowRepairApiCall({ id, nodeId, repairId })
    );

    if (res.payload instanceof Error) {
      return null;
    }

    return dispatch(
      updateServicesQuote({
        services: res.payload.diagnosisServices,
        totalCost: res.payload.totalCost,
      })
    );
  };

export const removeCustomRequestQuote =
  ({ repairId, id }: { repairId: string; id: number }) =>
  async (dispatch: Dispatch) => {
    const res: any = await dispatch(
      removeCustomRequestApiCall({ id, repairId })
    );
    if (res.payload instanceof Error) {
      return null;
    }

    return dispatch(
      updateServicesQuote({
        services: res.payload.diagnosisServices,
        totalCost: res.payload.totalCost,
      })
    );
  };

export const removeDiagnosisQuote =
  ({ repairId, id }: { repairId: string; id: number }) =>
  async (dispatch: Dispatch) => {
    const res: any = await dispatch(removeDiagnosisApiCall({ id, repairId }));
    if (res.payload instanceof Error) {
      return null;
    }

    return dispatch(
      updateServicesQuote({
        services: res.payload.diagnosisServices,
        totalCost: res.payload.totalCost,
      })
    );
  };

export const submitKnownRepairAndUpdateQuote =
  ({
    refNum,
    knownRepair,
  }: {
    refNum: string;
    knownRepair: {
      nodeId: number;
      name: string;
      message: string | null;
    };
  }) =>
  async (dispatch: Dispatch) => {
    const res: any = await dispatch(submitKnownRepair({ refNum, knownRepair }));

    if (res.payload instanceof Error) {
      return null;
    }

    return dispatch(
      updateServicesQuote({
        services: res.payload.diagnosisServices,
        totalCost: res.payload.totalCost,
      })
    );
  };

export const submitCustomRequestAndUpdateQuote =
  ({ refNum, customRequest }: { refNum: string; customRequest: string }) =>
  async (dispatch: Dispatch) => {
    const res: any = await dispatch(
      submitCustomRequest({ refNum, customRequest })
    );

    if (res.payload instanceof Error) {
      return null;
    }

    return dispatch(
      updateServicesQuote({
        services: res.payload.diagnosisServices,
        totalCost: res.payload.totalCost,
      })
    );
  };

export const submitDiagnosisQuote =
  ({ refNum, diagnosis }: { refNum: string; diagnosis: PreliminaryDiagnose }) =>
  async (dispatch: Dispatch) => {
    const res: any = await dispatch(submitDiagnosis({ refNum, diagnosis }));

    if (res.payload instanceof Error) {
      return null;
    }

    dispatch(
      updateServicesQuote({
        services: res.payload.diagnosisServices,
        totalCost: res.payload.totalCost,
      })
    );
  };

export const startBookingPath =
  ({
    appointmentData,
    repairIds,
  }: {
    appointmentData: AppointmentData;
    repairIds?: number[];
  }) =>
  async (dispatch: Dispatch) => {
    await (dispatch as ThunkDispatch<any, any, any>)(
      populateFlow(
        appointmentData.repairRequest.zip,
        appointmentData.diagnosis.diagnosisServices.knownRepairs as any,
        appointmentData.diagnosis.diagnosisServices.diagnoses as any,
        appointmentData.diagnosis.diagnosisServices.customRequests,
        appointmentData.car,
        appointmentData.car.vin
      )
    );

    dispatch(setStartPath(START_HOMEPAGE_BOOKING_PATH));

    const shop =
      appointmentData?.repairRequest.activeAppointment?.ispContactInfo;
    if (shop) {
      dispatch(setShop(shop as any));
      dispatch(setShopDetails(shop));
    } else {
      const shops: any = await (dispatch as ThunkDispatch<any, any, any>)(
        getShopsByZip({
          zip: appointmentData.repairRequest.zip,
          pageNumber: 0,
          size: 1,
          repairIds,
          car: {
            carId: { value: appointmentData.car.consumerCarId },
            year: {
              id: appointmentData.car.carYear,
              value: appointmentData.car.carYear,
            },
            make: {
              id: appointmentData.car.makeId,
              value: appointmentData.car.make,
            },
            model: {
              id: appointmentData.car.modelId,
              value: appointmentData.car.model,
            },
            mileage: { value: appointmentData.car.mileage },
            vin: { value: appointmentData.car.vin ?? null },
          },
        })
      );
      const shopUnderZip = shops?.payload[0];
      if (shopUnderZip) {
        dispatch(setShop(shopUnderZip));
        dispatch(setShopDetails(shopUnderZip));
      }
    }
  };

export const setHoursToFirstAvailableAppointment = createAction(
  `${serviceName}/SET_HOURS_TO_FIRST_AVAILABLE_APPOINTMENT`
);

export const throwAutoBookShownEvent = createAPIAction<
  [{ referenceNum: string; canAutoBook: boolean }]
>(`${serviceName}/THROW_AUTO_BOOK_EVENT`, ({ referenceNum, canAutoBook }) => ({
  endpoint: `/repair-service/consumer/repair-requests/${referenceNum}/event/auto-book-status?${queryString.stringify(
    { canAutoBook }
  )}`,
  method: 'PUT',
}));

export const setThrowAutoBookShownEvent = createAction(
  `${serviceName}/SET_THROW_AUTO_BOOK_SHOWN_EVENT`
);

export const throwRangeDisplayProvidedEvent = createAPIAction<
  [{ referenceNum: string; repairIds: number[] }]
>(`${serviceName}/THROW_RANGE_DISPLAY_PROVIDED_EVENT`, (data) => ({
  endpoint: `/repair-service/consumer/repair-requests/${data.referenceNum}/event/range-display-provided`,
  method: 'PUT',
  data: { repairIds: data.repairIds },
}));

export const acceptRecommendedRepairs = createAPIAction<
  [
    {
      consumerCarId: number;
      suggestedRepairIds: number[];
    },
  ],
  any,
  AcceptRecommendedRepairsResponse
>(
  `${serviceName}/ACCEPT_RECOMMENDED_REPAIRS`,
  ({ consumerCarId, suggestedRepairIds }) => {
    return {
      endpoint: '/repair-service/consumer/repair-requests',
      method: 'POST',
      data: { consumerCarId, suggestedRepairIds },
    };
  }
);

export const setNote = createAction<Partial<Note>>(
  `${serviceName}/SET_USER_NOTE`
);

export const getNote = createAPIAction<
  [{ consumerId: number; entityId: string; entityType: string }],
  any,
  Note
>(`${serviceName}/GET_NOTE`, ({ consumerId, entityId, entityType }) => {
  return {
    endpoint: `/repair-service/consumers/${consumerId}/notes/${entityId}/${entityType}/CONSUMER`,
    method: 'GET',
  };
});

export const updateNote = createAPIAction(
  `${serviceName}/UPDATE_NOTE`,
  ({ consumerId, noteId, content }) => {
    return {
      endpoint: `/repair-service/consumers/${consumerId}/notes/${noteId}`,
      method: 'PATCH',
      data: content,
    };
  }
);

export const deleteNote = createAPIAction<
  [
    {
      consumerId: number;
      noteId: string;
    },
  ]
>(`${serviceName}/DELETE_NOTE`, ({ consumerId, noteId }) => {
  return {
    endpoint: `/repair-service/consumers/${consumerId}/notes/${noteId}`,
    method: 'DELETE',
  };
});

export const getMostRecentZip =
  () =>
  async (
    dispatch: Dispatch,
    getState: () => RootStoreState
  ): Promise<string | undefined> => {
    const zipFromConsumer =
      selectMostRecentZip(getState()) || selectZip(getState());
    const timezoneFromConsumer =
      selectMostRecentTimezone(getState()) || selectTimezone(getState());
    const isLoggedIn = isUserLoggedIn(getState());

    if (isLoggedIn) {
      if (!zipFromConsumer) {
        try {
          const res: any = await dispatch(getAddresses());

          if (res?.payload?.length) {
            const addresses: any = res?.payload;
            const { zip, timezone } = addresses[0];

            if (timezone) {
              await dispatch(setTimezone(timezone));
            }

            if (zip) {
              await dispatch(setZip(zip));
              return zip;
            }
          }
        } catch (error) {
          return undefined;
        }
      } else {
        await dispatch(setZip(zipFromConsumer));
        await dispatch(setTimezone(timezoneFromConsumer));

        return zipFromConsumer;
      }
    }
  };

export const setCarDecodeError = createAction<string | number>(
  `${serviceName}/SET_CAR_DECODE_ERROR`
);

export const setDeliveryAddressIsCustom = createAction<boolean>(
  `${serviceName}/SET_DELIVERY_ADDRESS_IS_CUSTOM`
);

export const sendMobileValidationCode = createAPIAction<
  [
    {
      phoneNumber: string;
      consumerId: number;
    },
  ],
  any,
  any
>(`${serviceName}/SEND_MOBILE_VALIDATION_CODE`, (data) => {
  return {
    endpoint: '/consumer-service/phone-validation/code',
    method: 'POST',
    data,
  };
});

export const validateMobileCode = createAPIAction<
  [
    {
      code: string;
      consumerId: number;
      phoneNumber: string;
    },
  ],
  any,
  any
>(`${serviceName}/VALIDATE_MOBILE_CODE`, (data) => {
  return {
    endpoint: '/consumer-service/phone-validation/validate',
    method: 'POST',
    data,
  };
});

export const setRequestCredit = createAction<Credit>(
  `${serviceName}/SET_REQUEST_CREDIT`
);

export const saveAppointmentPreference = createAction<{
  date: Moment;
  time: string;
  foundSlotDay: any;
  foundSlotTime: any;
}>(`${serviceName}/SAVE_APPOINTMENT_PREFERENCE`);
